import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AnthropicService {
    private httpWithoutInterceptor: HttpClient;
    private apiUrl = "https://api-node-js-rqjv.onrender.com/api/message";

    constructor(private http: HttpClient, private httpBackend: HttpBackend) {
        this.httpWithoutInterceptor = new HttpClient(this.httpBackend);
    }

    sendMessage(textExtract: string): Observable<any> {
        const body = {
            model: "claude-3-haiku-20240307",
            max_tokens: 4096,
            messages: [
                {
                    role: "user",
                    content: [
                        {
                            type: "text",
                            text: textExtract,
                        },
                        {
                            type: "text",
                            text: `Based on the given text, generate all ABAP code including program interfaces, classes, functions and methods, create the classes using public definition. Create parameters with appropriate types: import, export and return. Create PUBLIC, PROTECTED and PRIVATE sections as required based on the text, for the classes generate in addition to definition also implementation.`,
                        },
                    ],
                },
            ],
        };

        const headers = new HttpHeaders({
            "X-API-Key":
                "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",
            "anthropic-version": "2023-06-01",
            "Content-Type": "application/json",
        });

        return this.httpWithoutInterceptor.post<any>(this.apiUrl, body, {
            headers,
            withCredentials: false,
        });
    }

    sendMessageClass(abapClass: string): Observable<any> {
        let body = {};

        body = {
            model: "claude-3-haiku-20240307",
            max_tokens: 4096,
            messages: [
                {
                    role: "user",
                    content: [
                        {
                            type: "text",
                            text: abapClass,
                        },
                        {
                            type: "text",
                            text: `Based on the given text, generate all ABAP code including program interfaces, classes, functions and methods, create the classes using public definition. Create parameters with appropriate types: import, export and return. Create PUBLIC, PROTECTED and PRIVATE sections as required based on the text, for the classes generate in addition to definition also implementation, do not include description of generated code.`,
                        },
                    ],
                },
            ],
        };

        const headers = new HttpHeaders({
            "X-API-Key":
                "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",
            "anthropic-version": "2023-06-01",
            "Content-Type": "application/json",
        });

        return this.httpWithoutInterceptor.post<any>(this.apiUrl, body, {
            headers,
            withCredentials: false,
        });
    }
}

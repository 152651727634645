<div bsModal #editModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="editModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #editionForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{l("EditEdition")}}: {{edition.edition.displayName}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')"
                        [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab heading="{{l('EditionProperties')}}">
                            <ng-template tabHeading>
                                <i *ngIf="editionNameInput.invalid"
                                    class="fas fa-exclamation-circle exclamation-tab"></i>
                            </ng-template>
                            <div class="form-group">
                                <label for="EditionDisplayName">{{l("EditionName")}} *</label>
                                <input id="EditionDisplayName" #editionNameInput="ngModel" class="form-control"
                                    type="text" name="EditionDisplayName" [ngClass]="edition.edition.displayName"
                                    [(ngModel)]="edition.edition.displayName" required
                                    [ngClass]="{'is-invalid': editionNameInput.invalid, 'is-valid': !editionNameInput.invalid}"
                                    maxlength="64">
                                <validation-messages [formCtrl]="editionNameInput"></validation-messages>
                            </div>
                        </tab>
                        <tab heading="{{l('Features')}}">
                            <app-feature-tree #featureTree [tenantId]="appSession.tenantId"></app-feature-tree>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-secondary"
                        (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="!editionForm.form.valid"
                        [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
                        <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>

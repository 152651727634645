<p-toast></p-toast>
<app-spinner></app-spinner>
<p-dialog #dialog header="Header" [modal]="true" [(visible)]="visibleModal" [style]="{width: '50vw'}"
    (onShow)="maximizeDialog()" (onHide)="onClose()">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">
            <i class="fa fa-laptop-code mr-2"></i> QIAMetrik | {{ setPromptAISettings(promptNumber) }}
        </span>
    </ng-template>
    <div class="kt-login__form">
        <div class="d-flex flex-row" style="height: 90dvh;">
            <section class="section-history" *ngIf="sidebarVisible">
                <div class="d-flex flex-column align-items-center">
                    <div class="d-flex flex-row justify-content-between mb-5 mt-2">
                        <button class="button-sidebar" (click)="resetChat()">New Chat</button>
                    </div>
                    <div class="charts-titles" *ngFor="let date of chatsTitlesByDates | keyvalue">
                        <span>
                            <strong>{{date.key}}</strong>
                        </span>
                        <p class="p-history" *ngFor="let chat of date.value" (click)="getChatHistory(chat, chat.date)">
                            {{truncateText(chat.chatTitle)}}
                        </p>
                    </div>
                </div>
            </section>
            <section [ngClass]="isPromptIA ? 'section-chat-prompt' : 'section-chat-noprompt'"
                [style]="sidebarVisible ? 'width: 82%;' : 'width: 100%;'">
                <div class="wrapper">
                    <div class="flex justify-content-center mt-4" *ngIf="!isPromptIA && promptNumber !== 3">
                        <span class="p-float-label">
                            <textarea pInputTextarea class="textarea-question" [(ngModel)]="userInput"
                                [autoResize]="true" (keydown.enter)="sendMessageNoPrompt()">
                            </textarea>
                            <label>
                                <strong>
                                    Informe o {{promptNumber == 2 ? 'programa' : 'código'}} que deseja
                                    {{promptNumber == 2 ? 'Documentar' : 'Refatorar'}}
                                </strong>
                            </label>
                        </span>
                    </div>
                    <div class="d-flex justify-content-end" *ngIf="!isPromptIA && promptNumber !== 3">
                        <button class="button-history mt-2 p-2" (click)="sendMessageNoPrompt()"
                            style="font-weight: 600;">
                            {{promptNumber == 2 ? 'Documentar' : 'Refatorar'}}
                        </button>
                    </div>
                    <p-progressSpinner class="d-flex justify-content-center align-items-center-5 pt-5"
                        *ngIf="loadingRegenerate">
                    </p-progressSpinner>
                    <div class="card flex justify-content-center" *ngIf="promptNumber === 3">
                        <div class="d-flex p-4">
                            <label>
                                <strong>Documentação Funcional</strong>
                            </label>
                        </div>

                        <div class="d-flex justify-content-between col-12 p-4">
                            <div class="file-container col-6" *ngIf="textResponse !== '' && !loading">
                                <labeL>Arquivos Gerados</labeL>
                                <app-archive-list [archives]="items"></app-archive-list>
                            </div>
                            <div class="d-flex col-6">
                                <p-fileUpload [customUpload]="true" (onSelect)="onUploaded($event)" [multiple]="false"
                                    accept="application/pdf" maxFileSize="1000000" chooseLabel="Selecionar Arquivo" (onClear)="clear()"
                                    uploadLabel="Documentar" cancelLabel="Cancelar" mode="advanced" [showUploadButton]="false">
                                </p-fileUpload>
                            </div>
                        </div>

                        <p-progressSpinner class="d-flex justify-content-center align-items-center-5 pt-5"
                            *ngIf="loading">
                        </p-progressSpinner>
                        <div *ngIf="textResponse !== '' && !loading">
                            <app-typewriter class="card p-4" [text]="textResponse"></app-typewriter>
                            <div class="flex mt-2 p-2">
                                <i class="fa fa-thumbs-up mr-2 icons-feedback"
                                    *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == true"
                                    [ngClass]="satisfactoryAnswer == true ? 'feedback-selected' : ''" tooltip="Good response"
                                    (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, true) : ''">
                                </i>
                                <i class="fa fa-thumbs-down mr-2 icons-feedback"
                                    *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == false"
                                    [ngClass]="satisfactoryAnswer == false ? 'feedback-selected' : ''" tooltip="Bad response"
                                    (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, false) : ''">
                                </i>
                                <i class="fa fa-copy mr-2 icons-feedback" tooltip="Copy" (click)="copyHTMLContent(textResponse)"
                                    *ngIf="!isCopy">
                                </i>
                                <i class="fa fa-check mr-2 icons-feedback" tooltip="Copy" *ngIf="isCopy">
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-content-center mt-5 pt-5" *ngIf="textResponse !== '' && !isPromptIA && promptNumber !== 3">
                        <label>
                            <strong>{{promptNumber == 2 ? 'Documentação' : 'Código Refatorado'}}</strong>
                        </label>
                        <div *ngIf="!loadingRegenerate || !loading">
                            <app-typewriter class="card p-4" [text]="textResponse"></app-typewriter>
                        </div>
                    </div>
                    <div class="flex mt-2" *ngIf="textResponse !== '' && !isPromptIA && (!loadingRegenerate || !loading) && promptNumber !== 3">
                        <i class="fa fa-undo mr-2 icons-feedback" tooltip="Regenerate"
                            (click)="regenerateQuestionNoPrompt(userInput)">
                        </i>
                        <i class="fa fa-thumbs-up mr-2 icons-feedback"
                            *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == true"
                            [ngClass]="satisfactoryAnswer == true ? 'feedback-selected' : ''" tooltip="Good response"
                            (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, true) : ''">
                        </i>
                        <i class="fa fa-thumbs-down mr-2 icons-feedback"
                            *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == false"
                            [ngClass]="satisfactoryAnswer == false ? 'feedback-selected' : ''" tooltip="Bad response"
                            (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, false) : ''">
                        </i>
                        <i class="fa fa-copy mr-2 icons-feedback" tooltip="Copy" (click)="copyHTMLContent(textResponse)"
                            *ngIf="!isCopy">
                        </i>
                        <i class="fa fa-check mr-2 icons-feedback" tooltip="Copy" *ngIf="isCopy">
                        </i>
                    </div>
                    <div class="chat-all" *ngIf="isPromptIA">
                        <div class="chat-messages">
                            <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="loading">
                                <p-progressSpinner></p-progressSpinner>
                            </div>
                            <div *ngFor="let chat of chatConversationHistory, let last = last">
                                <div class="user-message" *ngIf="!loading">
                                    <p [innerHTML]="chat.question"></p>
                                </div>
                                <div class="assistant-message" *ngIf="!loading">
                                    <p-progressSpinner class="response-assistant"
                                        *ngIf="chat.id == chatReloadId && loadingRegeneratePrompt">
                                    </p-progressSpinner>
                                    <textarea pInputTextarea class="textarea-response" [autoResize]="true"
                                        [innerHTML]="sanitizeText(chat.response)" [readonly]="true"
                                        [style]="chat.id == chatReloadId && loadingRegeneratePrompt ? 'display: none' : ''">
                                    </textarea>
                                    <div class="flex mt-2">
                                        <i class="fa fa-undo mr-2 icons-feedback" tooltip="Regenerate"
                                            (click)="regenerateQuestion(chat, true)">
                                        </i>
                                        <i class="fa fa-thumbs-up mr-2 icons-feedback"
                                            *ngIf="chat.satisfactoryAnswer == null || chat.satisfactoryAnswer == true"
                                            [ngClass]="chat.satisfactoryAnswer == true ? 'feedback-selected' : ''"
                                            tooltip="Good response"
                                            (click)="chat.satisfactoryAnswer == null ? setFeedbackUser(chat.id, true) : ''">
                                        </i>
                                        <i class="fa fa-thumbs-down mr-2 icons-feedback"
                                            *ngIf="chat.satisfactoryAnswer == null || chat.satisfactoryAnswer == false"
                                            [ngClass]="chat.satisfactoryAnswer == false ? 'feedback-selected' : ''"
                                            tooltip="Bad response"
                                            (click)="chat.satisfactoryAnswer == null ? setFeedbackUser(chat.id, false) : ''">
                                        </i>
                                        <i class="fa fa-copy mr-2 icons-feedback" tooltip="Copy"
                                            (click)="copyHTMLContent(chat.response, chat.id)" *ngIf="!isCopy">
                                        </i>
                                        <i class="fa fa-check mr-2 icons-feedback" tooltip="Copy"
                                            *ngIf="chat.id == chatReloadId && isCopy">
                                        </i>
                                    </div>
                                </div>
                                <div *ngIf="last" #scroll></div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-question" *ngIf="isPromptIA">
                        <div class="d-flex align-items-center ml-2 mr-2" tooltip="Gerar Código">
                            <p-inputSwitch [(ngModel)]="isSimpleOrComplete"></p-inputSwitch>
                            <i class="fa fa-laptop ml-2 mb-1" style="font-size: 11pt;"></i>
                        </div>
                        <div class="d-flex" style="width: 90%;">
                            <span class="p-input-icon-left mr-2" style="width: 100%;">
                                <i class="fa fa-search"></i>
                                <input pInputText type="text" [(ngModel)]="userInput" placeholder="Digite sua mensagem"
                                    (keydown.enter)="sendMessage()" style="width: 100%;">
                            </span>
                            <button class="button-chat mr-2" (click)="sendMessage()" tooltip="Enviar"
                                [disabled]="userInput == '' || loadingRegenerate || loading">
                                <i class="fa fa-paper-plane"></i>
                            </button>
                            <button class="button-history" (click)="openClosedSidebar()" tooltip="Histórico">
                                <i class="fa fa-history"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="textResponse !== '' && !isPromptIA" #scroll></div>
            </section>
        </div>
    </div>
</p-dialog>

<p-dialog header="Header" [(visible)]="visible" [style]="{ width: '60vw' }" [modal]="true" (onHide)="onCloseFeedback()">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Conte-nos mais?</span>
    </ng-template>
    <div class="d-flex flex-row justify-content-center mt-2">
        <input pInputText class="col-4 mr-2 input-feedback" type="text" value="O código estava incorreto"
            [readonly]="true" (click)="setFeedbackMessage('O código estava incorreto')" />
        <input pInputText class="col-4 mr-2 input-feedback" type="text" value="Recusei quando não deveria"
            [readonly]="true" (click)="setFeedbackMessage('Recusei quando não deveria')" />
        <input pInputText class="col-4 input-feedback" type="text" value="Não gostei do estilo"
            (click)="setFeedbackMessage('Não gostei do estilo')" />
    </div>
    <div class="d-flex flex-row justify-content-center mt-2">
        <input pInputText class="col-6 mr-2 input-feedback" type="text" value="Não segui as instruções completamente"
            [readonly]="true" (click)="setFeedbackMessage('Não segui as instruções completamente')" />
        <input pInputText class="col-4 mr-2 input-feedback" type="text" value="Não está correto de fato"
            [readonly]="true" (click)="setFeedbackMessage('Não está correto de fato')" />
        <input pInputText class="col-2 input-feedback" type="text" value="Outro..." (click)="isOther = true"
            [readonly]="true" />
    </div>
    <div class="d-flex flex-column align-items-center mt-5" *ngIf="isOther">
        <label class="col-10">Justificativa</label>
        <textarea pInputTextarea class="textarea-feedback" [autoResize]="true"
            placeholder="(Opcional) Sinta-se à vontade para adicionar detalhes específicos"
            [(ngModel)]="userFeedbackInput">
        </textarea>
    </div>
    <ng-template pTemplate="footer">
        <button [class]="userFeedbackInput == '' && !isOther ? 'button-modal-disabled' : 'button-modal'"
            (click)="setResponseFeedback()" [disabled]="userFeedbackInput == '' && !isOther">
            <i class="fa fa-share mr-1"></i> <span>Enviar</span>
        </button>
    </ng-template>
</p-dialog>

import { Injectable } from "@angular/core";
import pdfjsLib from "@app/pdf-worker";

@Injectable()
export class PdfReaderService {
    async extractTextFromPdf(file: File): Promise<string[]> {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;
        const textPages: string[] = [];

        for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const textContent = await page.getTextContent();
            const pageText = textContent.items
                .map((item: any) => item.str)
                .join(" ");
            textPages.push(pageText);
        }

        return textPages;
    }
}

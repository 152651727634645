import { Component, EventEmitter, Injector, OnInit, Output, ViewChild} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ExternalGMUDConfigurationDto, ExternalGMUDConfigurationServiceProxy, GestaoMudancaNativaServiceProxy, GestaoMudancaNativaViewDto, GetGestaoMudancaNativaAnexoForViewDto, RiscoMudanca, TipoMudanca, UsuariosAprovadoresViewDto } from "@shared/service-proxies/service-proxies";
import moment from "moment/moment";
import { ModalDirective } from "ngx-bootstrap/modal";
import { JustifyModalComponent } from "./justificativas/justify-modal/justify-modal.component";
import { FileDownloadService } from "@shared/utils/file-download.service";

interface EventItem {
    status?: string; 
    icon?: string;
}

@Component({
    selector: 'approve-reprove-change-modal',
    styleUrls: ['./approve-reprove-change-modal.component.less'],
    templateUrl: './approve-reprove-change-modal.component.html'
})

export class ApproveReproveChangeModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('approveReproveChangeModal', { static: true }) modal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('justifyModal', { static: true }) justifyModal: JustifyModalComponent;

    changeManagerNativo: GestaoMudancaNativaViewDto = new GestaoMudancaNativaViewDto();

    loading: boolean = false;
    btnAprovaReprova: boolean = false;
    gmud: boolean = false;
    statusGmud: EventItem[] = [];
    statusAprovador: EventItem[] = [];
    externalGmudConfigDto: ExternalGMUDConfigurationDto;
    statusFreezing: EventItem[] = [];

    constructor(
        injector: Injector,
        private _serviceGestaoMudancaNativo: GestaoMudancaNativaServiceProxy,
        private _fileDownloadService: FileDownloadService,
        private _gmudExternalAppService: ExternalGMUDConfigurationServiceProxy,
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        this.setStatusItems();
        this.getExternalGmudConfig();
     }

    setStatusItems() { 
        this.statusGmud = [
            { status: 'Aprovado', icon: 'fa fa-solid fa-check' },
            { status: 'Em análise', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Reprovado', icon: 'fa fa-solid fa-times' },
        ];

        this.statusAprovador = [
            { status: 'Aprovado', icon: 'fa fa-solid fa-check' },
            { status: 'Aprovado automaticamente', icon: 'fa fa-solid fa-user-check' },
            { status: 'Aguardando aprovação', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Reprovado', icon: 'fa fa-solid fa-times' }
        ];

        this.statusFreezing = [
            { status: 'Aprovado', icon: 'fa fa-solid fa-check' },
            { status: 'Aprovado automaticamente', icon: 'fa fa-solid fa-user-check' },
            { status: 'Aguardando análise', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Reprovado', icon: 'fa fa-solid fa-times' }
        ];
    }

    setColorsBackgroundStatus(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'background-color: #299F00;';
            case 'Aprovado automaticamente':
                return 'background-color: #299F00;';
            case 'Aguardando aprovação':
                return 'background-color: #ffa500;';
            case 'Em análise':
                return 'background-color: #ffa500;';
            case 'Reprovado':
                return 'background-color: #FF6464;';
        }
    }

    close(): void {
        this.modal.hide();
        this.closed.emit();
    }

    async show(id:string, gmud: boolean) {
        if (gmud){
            this.gmud = true;
            this.getGmudNativo(id);
        }
        else{
            this.getAproveDeploy(id);
        }

        this.modal.show();
    }

    getGmudNativo(gestaoMudancaId: string) {
        this._serviceGestaoMudancaNativo.getGestaoMudancaNativa(gestaoMudancaId).subscribe(result => {
            this.changeManagerNativo = result;

            this.btnAprovaReprova = true;
            this.validarStatusAprovarReprovar(result);
        });
    }

    validarStatusAprovarReprovar(result: GestaoMudancaNativaViewDto) {
        if (result.status === "Reprovado" || result.status === "Aprovado") {
            this.btnAprovaReprova = false;
        } else {      
            let usuarioAprovador: UsuariosAprovadoresViewDto;
            result.departamentos.forEach(x => {
                usuarioAprovador = x.usuarios.find(u => u.nome === this.appSession.userFullName);
            });
       
            if (usuarioAprovador.status === 'Aguardando aprovação' || usuarioAprovador.status === "")
                this.btnAprovaReprova = true;
            else
                this.btnAprovaReprova = false;
        }

        console.log(this.btnAprovaReprova);
    }

    getAproveDeploy(aproveDeployId: string) {
        this._serviceGestaoMudancaNativo.getGestaoMudancaNativa(aproveDeployId).subscribe(result => {
            this.changeManagerNativo = result;  
            
            if (result.statusTransporte === 'Aguardando análise')
                this.btnAprovaReprova = true;
        });
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString(): "";
        return new Date(dateString).toLocaleString();
    }
    
    //1 = aprovar e 2 = reprovar
    openModalJustify(approvalOrReproval: number) {
        let id: string = "";
        if (this.gmud){
            id = this.changeManagerNativo.id;
        }else{
            id = this.changeManagerNativo.transporteId;
        }
        this.justifyModal.show(approvalOrReproval, id, this.gmud);
    }

    downloadAnexo(anexo: GetGestaoMudancaNativaAnexoForViewDto) {
        this._fileDownloadService.downloadBinaryFile(
            anexo.fileStorageId,
            anexo.fileType,
            anexo.nomeAnexo,
            this.appSession.tenantId
        );
    }

    getEnumTipoMudanca(tipoMudanca: TipoMudanca) {
        switch (tipoMudanca) {
            case TipoMudanca.Emergencial:
                return "Emergencial";
            case TipoMudanca.JanelaDeTransporte:
                return "Janela de transporte";         
            case TipoMudanca.Manual:
                return "Manual";
            case TipoMudanca.Planejado:
                return "Planejado";
            default:
                return "";
        }
    }

    getEnumRisco(risco: RiscoMudanca) {
        switch (risco) {
            case RiscoMudanca.Nenhum:
                return "Nenhum";
            case RiscoMudanca.Baixo:
                return "Baixo";         
            case RiscoMudanca.MuitoBaixo:
                return "Muito baixo";
            case RiscoMudanca.Moderado:
                return "Moderado";
            case RiscoMudanca.Alto:
                return "Alto";
            case RiscoMudanca.MuitoAlto:
                return "Muito alto";
            default:
                return "";
        }
    }

    getExternalGmudConfig() {
        this._gmudExternalAppService.getConfiguration().subscribe(res => { this.externalGmudConfigDto = res });
    }

    changeManagerTabView(): boolean {
        if (this.externalGmudConfigDto != null && this.externalGmudConfigDto != undefined && this.externalGmudConfigDto.active) {
            return true;
        }
        return false;
    }

    approvalFreezingTabView(): boolean {
        if (this.changeManagerNativo != null && this.changeManagerNativo.approvalFreezing != null &&
            this.changeManagerNativo.approvalFreezing != undefined &&
            this.changeManagerNativo.approvalFreezing.length > 0
        ) {
            return true;
        }
        return false;
    }

    setColorsBackgroundStatusFreezing(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'background-color: #299F00;';
            case 'Aprovado automaticamente':
                return 'background-color: #299F00;';
            case 'Aguardando aprovação':
                return 'background-color: #ffa500;';
            case 'Aguardando análise':
                return 'background-color: #ffa500;';
            case 'Reprovado':
                return 'background-color: #FF6464;';
        }
    }
}
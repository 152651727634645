<div bsModal #changeManagerModal="bs-modal" class="modal fade change-modal" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="card flex justify-content-center gap-2">
        <p-toast></p-toast>
    </div>
    <div class="modal-dialog modal-xl">
        <div class="modal-content change-modal">
            <form #changeManagerForm="ngForm" (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{"CreateChangeManagerHeader" | localize}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    <div class="form-group">
                        <label for="change-title">{{"ChangeTitle" | localize}} *</label>
                        <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                            class="form-control" [(ngModel)]="changeManager.titulo" disabled />
                    </div>
                    <div class="form-group">
                        <label for="change-description">{{"Descrição Demanda" | localize}} *</label>
                        <textarea id="change-description" #changeDescription="ngModel" type="text"
                            name="ChangeDescription" class="form-control textarea" [(ngModel)]="changeManager.descricao"
                            disabled></textarea>
                    </div>
                    <div class="form-group">
                        <label for="change-uDescription">{{"ChangeDescription" | localize}} *</label>
                        <textarea id="change-uDescription" #changeDescription="ngModel" type="text"
                            name="ChangeUDescription" class="form-control textarea"
                            [(ngModel)]="changeManager.uDescricao"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="change-comements">{{"ChangeComments" | localize}} *</label>
                        <textarea id="change-comements" #changeComments="ngModel" type="text" name="ChangeComments"
                            class="form-control textarea" [(ngModel)]="changeManager.comentario"></textarea>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-3 d-flex flex-column mb-2">
                            <label for="changeType">{{l('Tipo de transporte')}}</label>
                            <p-dropdown Id="changeType" class="form-group changeType-content" [options]="types"
                                (onChange)="onValueChange($event)" [autoDisplayFirst]="true" optionLabel="name"
                                optionValue="value" [disabled]="isNotFreezing">
                            </p-dropdown>
                        </div>
                        <div class="datetimeDeploy-content col-12 col-md-3 d-flex flex-column mb-2" *ngIf="isPlanned">
                            <label for="change-dateTimeDeploy">{{l('Data e hora do transporte')}}</label>
                            <p-calendar id="change-dateTimeDeploy" name="datetimeDeploy" [(ngModel)]="datetimeDeploy"
                                [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="col-12 col-md-3 d-flex flex-column mb-2">
                            <label for="change-start">{{"ChangeStart" | localize}} *</label>
                            <p-calendar id="change-start" name="ChangeStart" [(ngModel)]="changeManager.dataInicio"
                                [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true" #changeStart="ngModel">
                            </p-calendar>
                            <validation-messages [formCtrl]="changeStart"></validation-messages>
                        </div>
                        <div class="col-12 col-md-3 d-flex flex-column mb-2">
                            <label for="change-end">{{"ChangeEnd" | localize}} *</label>
                            <p-calendar id="change-end" name="ChangeEnd" [(ngModel)]="changeManager.dataFim"
                                [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true" #changeEnd="ngModel">
                            </p-calendar>
                            <validation-messages [formCtrl]="changeEnd"></validation-messages>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isWindowsTransport">
                        <label for="windows-transp">{{l('Data da janela de transporte')}}</label>
                        <input id="windows-transp" #changeTitle="ngModel" type="text" name="WindowsTransport"
                            class="form-control" [(ngModel)]="windowsTransport" disabled />
                        <br>
                    </div>
                    <div class="form-group">
                        <label for="return-plan">{{"ReturnPlan" | localize}} *</label>
                        <input id="return-plan" #returnPlan="ngModel" type="text" name="ReturnPlan" class="form-control"
                            [(ngModel)]="changeManager.planoRetorno" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{"Cancel" |
                        localize}}</button>
                    <button type="submit" class="btn btn-primary blue"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <app-information-deploy-modal #informationDeployModal (closed)="closeInformation()"
        (modalSave)="finalizeDemand()"></app-information-deploy-modal>
</div>

import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ConfigurationSettingsTenantsServiceProxy, GeralConfigurationTenantsDto, User, UsuariosAprovadoresDeploySelect2 } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-config-tenants',
  templateUrl: './config-tenants.component.html',
  styleUrls: ['./config-tenants.component.less']
})
export class ConfigTenantsComponent extends AppComponentBase implements OnInit {

  settings: GeralConfigurationTenantsDto = new GeralConfigurationTenantsDto();
  loading = false;
  usersIgnoreFilter: UsuariosAprovadoresDeploySelect2[] = [];
  usersIgnoreFilterSelected: UsuariosAprovadoresDeploySelect2[] = [];

  constructor(
    injector: Injector,
    private _configurationSettingsTenantsService: ConfigurationSettingsTenantsServiceProxy
  ) {
    super(injector);
  }
  ngOnInit() {
    this.getSettings();
    this.selectUsersIgnoreFilter();
    this.getUsersIgnoreFilter();
  }

  getSettings() {
    this.loading = true;
    this._configurationSettingsTenantsService.getConfiguration()
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((result: GeralConfigurationTenantsDto) => this.settings = result);
  }

  saveAll() {
    this.loading = true;
    if (this.usersIgnoreFilterSelected != null &&
        this.usersIgnoreFilterSelected != undefined &&
        this.usersIgnoreFilterSelected.length > 0)
      {
        this.settings.usersId = [];
        this.usersIgnoreFilterSelected.forEach(user => {
          this.settings.usersId.push(Number(user.value));
        });
      }


    this._configurationSettingsTenantsService.insertOrUpdate(this.settings)
      .pipe(finalize(() => this.loading = false))
      .subscribe()
  }

  clearUser(item: any) {
    this.usersIgnoreFilterSelected = [].concat(this.usersIgnoreFilterSelected.filter(x => x.value !== item.value));
  }
  
  selectUsersIgnoreFilter(): void {
    this.usersIgnoreFilterSelected = [];
    this._configurationSettingsTenantsService.getAllRemoveUserFilterDemand().subscribe(x => { this.usersIgnoreFilter = x; });
  }

  getUsersIgnoreFilter() {
    this._configurationSettingsTenantsService.getRemoveUserFilterDemand()
        .subscribe(res => { this.usersIgnoreFilterSelected = res });
  }
}

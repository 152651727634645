<form (ngSubmit)="saveAll()" #changeForm="ngForm">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>Configurações Gerais</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                Configurações gerais do tenant
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button class="btn btn-primary" type="submit">
                    <i class="la la-floppy-o"></i>
                    {{"SaveAll" | localize}}
                </button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet">
            <p-progressSpinner *ngIf="loading; else portletBody"></p-progressSpinner>
            <ng-template #portletBody>
                <div class="kt-portlet__body">
                    <label for="config-enabled" class="kt-checkbox">
                        <input id="config-enabled" type="checkbox" name="ActiveValidateReference"
                            [(ngModel)]="settings.activeValidateReference"> {{"Habilitar validação de referência na demanda" | localize}}
                        <span></span>
                    </label>
                    <div class="form-group">
                        <label for="usersIgnoreFilterSelected" id="approvers-label" class="approvers-label">{{l("Selecione o usuário para remover do filtro de demandas")}}</label>
                        <div class="row">
                            <ng-select class="col-12 col-md-10" [items]="usersIgnoreFilter" [multiple]="true" [(ngModel)]="usersIgnoreFilterSelected" name="usersIgnoreFilterSelected">
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="box-selected">
                                        <span class="ng-value-label ng-star-inserted">
                                            <b>{{item.label}}</b>
                                            <div class="box-content">
                                                <div class="content-item item-cargo">{{item.cargo}}</div>
                                                <div class="content-item item-fornecedor">{{item.fornecedor}}</div>
                                            </div>
                                        </span>
                                        <span class="delete-box" (click)="clearUser(item)" aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    <div class="p-2">
                                        <b>{{item.label}}</b>
                                        <div style="font-size: 12px;">{{item.cargo}}</div>
                                        <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                    </div>
                                </ng-template>
                            </ng-select> 
                            <div class="col-12 col-md-2 button-content">
                                <!--
                                <button id="btn-user" type="submit" class="btn btn-primary btn-user" (click)="addApprovalUser()">
                                    <i class="fa fa-save"></i><span>{{l("Save")}}</span>
                                </button>
                                -->
                            </div>
                        </div>
                        <label class="mt-1"><strong>*Atenção: </strong>Ao adicionar o usuário aqui, ao consultar o kamban será exibido as demandas de todos os usuários</label>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</form>
import { Component, EventEmitter, Injector, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditDemandaDto, DemandasServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-information-demand-modal',
  templateUrl: './informationDemand.component.html',
  styleUrls: ['./informationDemand.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class InformationDemandModalComponent extends AppComponentBase {
  @ViewChild('informationDemandModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  titleModal: string;
  information: string;
  demand: CreateOrEditDemandaDto = new CreateOrEditDemandaDto();

  constructor(injector: Injector,
              private _demandasServiceProxy: DemandasServiceProxy,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  async show(title: string,  information: string, demand: CreateOrEditDemandaDto) {
    this.titleModal = title;
    this.information = information;
    this.demand = demand;
    this.modal.show();
  }

  close(): void {
    this.modal.hide();
    this.closed.emit();
  }

  createDemand() {
    this._demandasServiceProxy.createOrEdit(this.demand)
        .subscribe(() => {
          this.notify.info(this.l('SavedSuccessfully'));
          this.close();
          this.modalSave.emit(null);
        });
  }
}

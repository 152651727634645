<div class="card d-flex justify-content-center">
    <!-- <p-listbox
        [options]="archives"
        [(ngModel)]="selectedArchive"
        optionLabel="label"
        optionValue="value"
        [style]="{'width':'15rem'}"
        [listStyle]="{'max-height': '220px'}" /> -->
    <p-listbox
        [options]="archives"
        [(ngModel)]="selectedArchive"
        optionLabel="label"
        optionValue="value"
        (onClick)="onSelectedItem($event)"
        [listStyle]="{ 'max-height': '250px' }"
        [listStyle]="{'max-height': '220px'}">
            <ng-template let-archive pTemplate="item">
                <div class="d-flex justify-content-center align-items-center gap-3">
                    <i class="fa fa-file-word mr-2"></i>
                    <div class="mr-2">{{ archive.label }}</div>
                    <i class="fa fa-download"></i>
                </div>
            </ng-template>
    </p-listbox>
</div>

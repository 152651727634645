
export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.production.json',
    appUrl: 'https://qametrikui-dev.qametrik.com/',
    serverUrl:'https://qametrikui-dev.qametrik.com/',
    deployUrl:'https://qametrikui-dev.qametrik.com/',
    clarityUrl: 'https://clarity.microsoft.com/projects/view/mpr9elld2x/dashboard?date=Last%203%20days',
    gitbookUrl: 'https://qametrik.gitbook.io/qametrik-devops/'
};

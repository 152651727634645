import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCommonModule } from "@app/shared/common/app-common.module";
import { MinhasSenhasComponent } from "./minhasSenhas/minhasSenhas/minhasSenhas.component";
import { ViewMinhaSenhaModalComponent } from "./minhasSenhas/minhasSenhas/view-minhaSenha-modal/view-minhaSenha-modal.component";
import { CreateOrEditMinhaSenhaModalComponent } from "./minhasSenhas/minhasSenhas/create-or-edit-minhaSenha-modal/create-or-edit-minhaSenha-modal.component";
import { MinhaSenhaAmbienteLookupTableModalComponent } from "./minhasSenhas/minhasSenhas/minhaSenha-ambiente-lookup-table/minhaSenha-ambiente-lookup-table-modal.component";
import { MinhaSenhaUsuarioSapLookupTableModalComponent } from "./minhasSenhas/minhasSenhas/minhaSenha-usuarioSap-lookup-table/minhaSenha-usuarioSap-lookup-table-modal.component";

import { AmbientesComponent } from "./ambientes/ambientes/ambientes.component";
import { CreateOrEditAmbienteModalComponent } from "./ambientes/ambientes/create-or-edit-ambiente-modal/create-or-edit-ambiente-modal.component";

import { ModulosComponent } from "./modulos/modulos/modulos.component";
import { ViewModuloModalComponent } from "./modulos/modulos/view-modulo-modal/view-modulo-modal.component";
import { CreateOrEditModuloModalComponent } from "./modulos/modulos/create-or-edit-modulo-modal/create-or-edit-modulo-modal.component";

import { FornecedoresComponent } from "./fornecedores/fornecedores/fornecedores.component";
import { ViewFornecedorModalComponent } from "./fornecedores/fornecedores/view-fornecedor-modal/view-fornecedor-modal.component";
import { CreateOrEditFornecedorModalComponent } from "./fornecedores/fornecedores/create-or-edit-fornecedor-modal/create-or-edit-fornecedor-modal.component";

import { CargosComponent } from "./cargos/cargos/cargos.component";
import { CreateOrEditCargoModalComponent } from "./cargos/cargos/create-or-edit-cargo-modal/create-or-edit-cargo-modal.component";

import { UtilsModule } from "@shared/utils/utils.module";

import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import {
    BsDatepickerModule,
    BsDatepickerConfig,
    BsDaterangepickerConfig,
    BsLocaleService,
} from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";

import { AutoCompleteModule } from "primeng/autocomplete";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { PaginatorModule } from "primeng/paginator";
import { EditorModule } from "primeng/editor";
import { InputMaskModule } from "primeng/inputmask";
import { FileUploadModule } from "primeng/fileupload";

// import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneCdkModule } from "@ngx-dropzone/cdk";

import { TableModule } from "primeng/table";
import { TreeModule } from "primeng/tree";
import { DragDropModule } from "primeng/dragdrop";
import { TreeDragDropService } from "primeng/api";
import { ContextMenuModule } from "primeng/contextmenu";
import { AdminRoutingModule } from "./admin-routing.module";
import { AuditLogDetailModalComponent } from "./audit-logs/audit-log-detail-modal/audit-log-detail-modal.component";
import { AuditLogsComponent } from "./audit-logs/audit-logs.component";
import { HostDashboardComponent } from "./dashboard/host-dashboard.component";
import { CreateEditionModalComponent } from "./editions/create-edition-modal/create-edition-modal.component";
import { EditEditionModalComponent } from "./editions/edit-edition-modal/edit-edition-modal.component";
import { MoveTenantsToAnotherEditionModalComponent } from "./editions/move-tenants-to-another-edition/move-tenants-to-another-edition-modal.component";
import { EditionsComponent } from "./editions/editions.component";
import { InstallComponent } from "./install/install.component";
import { CreateOrEditLanguageModalComponent } from "./languages/create-or-edit-language-modal/create-or-edit-language-modal.component";
import { EditTextModalComponent } from "./languages/edit-text-modal/edit-text-modal.component";
import { LanguageTextsComponent } from "./languages/language-texts/language-texts.component";
import { LanguagesComponent } from "./languages/languages.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { CreateOrEditRoleModalComponent } from "./roles/create-or-edit-role-modal/create-or-edit-role-modal.component";
import { RolesComponent } from "./roles/roles.component";
import { HostSettingsComponent } from "./settings/host-settings/host-settings.component";
import { TenantSettingsComponent } from "./settings/tenant-settings/tenant-settings.component";
import { EditionComboComponent } from "./shared/edition-combo.component";
import { FeatureTreeComponent } from "./shared/feature-tree.component";
import { PermissionComboComponent } from "./shared/permission-combo.component";
import { PermissionTreeComponent } from "./shared/permission-tree.component";
import { RoleComboComponent } from "./shared/role-combo.component";
import { InvoiceComponent } from "./subscription-management/invoice/invoice.component";
import { SubscriptionManagementComponent } from "./subscription-management/subscription-management.component";
import { CreateTenantModalComponent } from "./tenants/create-tenant-modal/create-tenant-modal.component";
import { EditTenantModalComponent } from "./tenants/edit-tenant-modal/edit-tenant-modal.component";
import { TenantFeaturesModalComponent } from "./tenants/tenant-features-modal/tenant-features-modal.component";
import { TenantsComponent } from "./tenants/tenants.component";
import { UiCustomizationComponent } from "./ui-customization/ui-customization.component";
import { DefaultThemeUiSettingsComponent } from "./ui-customization/default-theme-ui-settings/default-theme-ui-settings.component";
import { CreateOrEditUserModalComponent } from "./users/create-or-edit-user-modal/create-or-edit-user-modal.component";
import { EditUserPermissionsModalComponent } from "./users/edit-user-permissions-modal/edit-user-permissions-modal.component";
import { MaquinasUserModalComponent } from "./users/maquinas-user-modal/maquinas-user-modal.component";
import { ImpersonationService } from "./users/impersonation.service";
import { UsersComponent } from "./users/users.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxBootstrapDatePickerConfigService } from "assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service";
import { VisualizarFornecedoresComponent } from "./fornecedores/visualizar-fornecedores/visualizar-fornecedores.component";
import { ViewResponsaveisComponent } from "./fornecedores/visualizar-fornecedores/view-responsaveis/view-responsaveis.component";
import { AdicionarFornecedoresComponent } from "./fornecedores/visualizar-fornecedores/adicionar-fornecedores/adicionar-fornecedores.component";
import { NgSelectModule } from "@ng-select/ng-select";
import {
    IConfig,
    NgxMaskDirective,
    NgxMaskPipe,
    provideNgxMask,
} from "ngx-mask";
import { ItemAmbienteComponent } from "./ambientes/ambientes/item-ambiente/item-ambiente.component";
import { CardItemComponent } from "./ambientes/ambientes/item-ambiente/card-item/card-item.component";
import { MatTabsModule } from "@angular/material/tabs";
import { TrainingVideosComponent } from "./training-videos/training-videos.component";
import { SuporteComponent } from "./suporte/suporte.component";
import { videoViewModalComponent } from "./training-videos/video-viewer-modal/video-viewer-modal.component";
import { ExternalIntegrationComponent } from "./external-integration/external-integration.component";
import { CheckboxModule } from "primeng/checkbox";
import { MessagesModule } from "primeng/messages";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { UploadDashboardFileComponent } from "./upload-dashboard-file/upload-dashboard-file.component";
import { LdapComponent } from "./external-integration/ldap/ldap.component";
import { MicrosoftComponent } from "./external-integration/microsoft/microsoft.component";
import { AdComponent } from "./external-integration/ad/ad.component";
import { CreateSapUserModalComponent } from "./users/create-sap-user-modal/create-sap-user-modal.component";
import { ChangeManagerComponent } from "./change-manager/change-manager.component";
import { CriarIntegracaoModalComponent } from "./ambientes/ambientes/criar-integracao-modal/criar-integracao-modal.component";
import { MandanteIntegracaoComponent } from "./ambientes/ambientes/mandante-integracao/mandante-integracao.component";
import { TabViewModule } from "primeng/tabview";
import { DocumentationComponent } from "./documentation/documentation.component";
import { CreateOrEditdocumentationModalComponent } from "./documentation/create-or-edit-documentation-modal/create-or-editdocumentation-modal.component";
import { NotificacaoPortalComponent } from "./notificacao-portal/notificacao-portal.component";
import { CreateNotificacaoModalComponent } from "./notificacao-portal/create-notificacao-modal/create-notificacao-modal.component";
import { VersionamentoSapComponent } from "./versionamento/versionamento-sap.component";
import { CreateVersionamentoComponent } from "./versionamento/create-versionamento-modal/create-versionamento-modal.component";
import { DeployCustomersComponent } from "./versionamento/deploy-customers-modal/deploy-customers-modal.component";
import { DropzoneMaterialModule } from "@ngx-dropzone/material";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ConfigurationStmsImportComponent } from "./stms-import/stms-import.component";
import { TreeSelectModule } from "primeng/treeselect";
import { ConfigTenantsComponent } from "./config-tenants/config-tenants.component";
export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    imports: [
        MatCheckboxModule,
        MatRadioModule,
        CheckboxModule,
        TabViewModule,
        MessagesModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        CommonModule,
        FileUploadModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AdminRoutingModule,
        UtilsModule,
        AppCommonModule,
        TableModule,
        TreeModule,
        TreeSelectModule,
        DragDropModule,
        ContextMenuModule,
        PaginatorModule,
        ProgressSpinnerModule,
        AutoCompleteModule,
        EditorModule,
        InputMaskModule,
        NgxChartsModule,
        ImageCropperModule,
        NgSelectModule,
        TimepickerModule.forRoot(),
        NgxMaskDirective,
        NgxMaskPipe,
        DropzoneCdkModule,
        DropzoneMaterialModule,
        MatFormFieldModule,
    ],
    declarations: [
        MinhasSenhasComponent,
        ViewMinhaSenhaModalComponent,
        CreateOrEditMinhaSenhaModalComponent,
        MinhaSenhaAmbienteLookupTableModalComponent,
        MinhaSenhaUsuarioSapLookupTableModalComponent,
        ModulosComponent,
        ViewModuloModalComponent,
        CreateOrEditModuloModalComponent,
        AmbientesComponent,
        CreateOrEditAmbienteModalComponent,
        ModulosComponent,
        ViewModuloModalComponent,
        CreateOrEditModuloModalComponent,
        FornecedoresComponent,
        ViewFornecedorModalComponent,
        CreateOrEditFornecedorModalComponent,
        CargosComponent,
        CreateOrEditCargoModalComponent,
        UsersComponent,
        PermissionComboComponent,
        RoleComboComponent,
        CreateOrEditUserModalComponent,
        EditUserPermissionsModalComponent,
        MaquinasUserModalComponent,
        PermissionTreeComponent,
        FeatureTreeComponent,
        RolesComponent,
        CreateOrEditRoleModalComponent,
        AuditLogsComponent,
        AuditLogDetailModalComponent,
        HostSettingsComponent,
        InstallComponent,
        MaintenanceComponent,
        EditionsComponent,
        CreateEditionModalComponent,
        EditEditionModalComponent,
        MoveTenantsToAnotherEditionModalComponent,
        LanguagesComponent,
        LanguageTextsComponent,
        CreateOrEditLanguageModalComponent,
        TenantsComponent,
        CreateTenantModalComponent,
        EditTenantModalComponent,
        TenantFeaturesModalComponent,
        CreateOrEditLanguageModalComponent,
        EditTextModalComponent,
        TenantSettingsComponent,
        HostDashboardComponent,
        EditionComboComponent,
        InvoiceComponent,
        SubscriptionManagementComponent,
        UiCustomizationComponent,
        DefaultThemeUiSettingsComponent,
        VisualizarFornecedoresComponent,
        ViewResponsaveisComponent,
        AdicionarFornecedoresComponent,
        ItemAmbienteComponent,
        CardItemComponent,
        TrainingVideosComponent,
        SuporteComponent,
        videoViewModalComponent,
        ExternalIntegrationComponent,
        UploadDashboardFileComponent,
        LdapComponent,
        MicrosoftComponent,
        AdComponent,
        CreateSapUserModalComponent,
        ChangeManagerComponent,
        CriarIntegracaoModalComponent,
        MandanteIntegracaoComponent,
        DocumentationComponent,
        CreateOrEditdocumentationModalComponent,
        NotificacaoPortalComponent,
        CreateNotificacaoModalComponent,
        VersionamentoSapComponent,
        CreateVersionamentoComponent,
        DeployCustomersComponent,
        ConfigurationStmsImportComponent,
        ConfigTenantsComponent,
    ],
    exports: [
        // AddRoleModalComponent
    ],
    providers: [
        ImpersonationService,
        TreeDragDropService,
        {
            provide: BsDatepickerConfig,
            useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig,
        },
        {
            provide: BsDaterangepickerConfig,
            useFactory:
                NgxBootstrapDatePickerConfigService.getDaterangepickerConfig,
        },
        {
            provide: BsLocaleService,
            useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale,
        },
        provideNgxMask(),
    ],
})
export class AdminModule {}

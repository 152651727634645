import { Component, Injector, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { PromptAIConfigServiceProxy } from "@shared/service-proxies/service-proxies";
import { PromptAIConfigDto } from "./../../../../shared/service-proxies/service-proxies";
import { PromptAIConfig } from "@app/shared/interfaces/prompt-ai-config";
import { Router } from "@angular/router";
import { PromptAIEnum } from "@app/shared/enums/prompt-ai-enum";

@Component({
    selector: "app-prompt-selection",
    templateUrl: "./prompt-selection.component.html",
    styleUrls: ["./prompt-selection.component.less"],
})
export class PromptSelectionComponent
    extends AppComponentBase
    implements OnInit
{
    promptAIConfig = {} as PromptAIConfigDto;
    promptAIConfigOptions: PromptAIConfig[] = [];

    constructor(
        injector: Injector,
        private router: Router,
        private _promptAIConfigService: PromptAIConfigServiceProxy
    ) {
        super(injector);
    }

    async ngOnInit() {
        await this.getAiConfigs();
    }

    async getAiConfigs() {
        await this._promptAIConfigService
            .getPromptAISettings(this.appSession.tenantId)
            .subscribe((result) => {
                this.promptAIConfig = result;
                this.setPromptAISettingsOptions(result);
            })
            .add();
    }

    setPromptAISettingsOptions(promptAIConfig: PromptAIConfigDto) {
        if (promptAIConfig.codeGenerate)
            this.promptAIConfigOptions.push({
                label: "Gerar Código",
                icon: "fa fa-laptop",
                value: PromptAIEnum.CodeGenerate,
            });

        if (promptAIConfig.codeReview)
            this.promptAIConfigOptions.push({
                label: "Gerar Revisão de código",
                icon: "fa fa-code",
                value: PromptAIEnum.CodeReview,
            });

        if (promptAIConfig.documentation)
            this.promptAIConfigOptions.push({
                label: "Gerar Documentação",
                icon: "fa fa-file",
                value: PromptAIEnum.Documentation,
            });

        // this.promptAIConfigOptions.push({
        //     label: "Histórico Documentação",
        //     icon: "fa fa-history",
        //     value: PromptAIEnum.DocumentationHistory,
        // });

        if (promptAIConfig.classDiagramReading)
            this.promptAIConfigOptions.push({
                label: "Documentação Funcional",
                icon: "fa fa-file-pdf",
                value: PromptAIEnum.ClassDiagramReading,
            });
    }

    setPrompt(promptId: PromptAIEnum) {
        if (promptId == PromptAIEnum.DocumentationHistory)
            this.router.navigateByUrl(
                `/app/main/documentation-history/${promptId}`
            );
        else this.router.navigateByUrl(`/app/main/prompt-chat/${promptId}`);
    }
}

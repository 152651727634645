import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-typewriter",
    templateUrl: "./typewriter.component.html",
    styleUrls: ["./typewriter.component.scss"],
})
export class TypewriterComponent implements OnInit {
    @Input() text: string = "";
    displayText: string = "";
    typingSpeed: number = 10;

    ngOnInit(): void {
        this.startTyping();
    }

    startTyping() {
        let index = 0;

        const typingInterval = setInterval(() => {
            this.displayText += this.text[index];
            index++;

            if (index >= this.text.length) {
                clearInterval(typingInterval);
            }
        }, this.typingSpeed);
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCommonModule } from "@app/shared/common/app-common.module";
import { AvaliacoesDeQualidadesComponent } from "./avaliacoesDeQualidade/avaliacoesDeQualidades/avaliacoesDeQualidades.component";
import { LiberacoesUsuariosComponent } from "./liberacoesUsuarios/liberacoesUsuarios/liberacoesUsuarios.component";
import { CardLiberacaoUsuarioComponent } from "./liberacoesUsuarios/card-liberacao-usuario/card-liberacao-usuario.component";
import { DemandaModuloLookupTableModalComponent } from "./demandas/demandas/demanda-modulo-look-up-table-modal/demanda-modulo-lookup-table-modal.component";
import { KanBanAmbienteModalComponent } from "./demandas/demandas/kanban-ambiente-modal/kanban-ambiente-modal.component";
import { DemandasComponent } from "./demandas/demandas/demandas.component";
import { KanbanComponent } from "./demandas/demandas/kanban/kanban.component";
import { CreateOrEditDemandaModalComponent } from "./demandas/demandas/create-or-edit-demanda-modal/create-or-edit-demanda-modal.component";
import { DemandaUserLookupTableModalComponent } from "./demandas/demandas/demanda-user-lookup-table-modal/demanda-user-lookup-table-modal.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { PaginatorModule } from "primeng/paginator";
import { EditorModule } from "primeng/editor";
import { InputMaskModule } from "primeng/inputmask";
import { FileUploadModule } from "primeng/fileupload";
import { TableModule } from "primeng/table";
import { TreeModule } from "primeng/tree";
import { NgSelectModule } from "@ng-select/ng-select";
import { DropzoneCdkModule } from "@ngx-dropzone/cdk";
import { PanelModule } from "primeng/panel";
import {
    IConfig,
    NgxMaskDirective,
    NgxMaskPipe,
    provideNgxMask,
} from "ngx-mask";
import { UtilsModule } from "@shared/utils/utils.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MainRoutingModule } from "./main-routing.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import {
    BsDatepickerModule,
    BsDatepickerConfig,
    BsDaterangepickerConfig,
    BsLocaleService,
} from "ngx-bootstrap/datepicker";
import { BoxKanbanComponent } from "./demandas/demandas/box-kanban/box-kanban.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { GridsterModule } from "angular-gridster2";
import { HistoricoAvaliacaoCodigoComponent } from "./avaliacoesDeQualidade/historico-avaliacao-codigo/historico-avaliacao-codigo.component";
import { ItensAvaliadosComponent } from "./avaliacoesDeQualidade/itens-avaliados/itens-avaliados.component";
import { NgxBootstrapDatePickerConfigService } from "assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service";
import { CodigoSapComponent } from "./avaliacoesDeQualidade/codigo-sap/codigo-sap.component";
NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales();
export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};
import { JustificativaAvaliacaoComponent } from "./avaliacoesDeQualidade/justificativa-avaliacao/justificativa-avaliacao.component";
import { JustificativaDecisorComponent } from "./avaliacoesDeQualidade/justificativa-decisor/justificativa-decisor.component";
import { MatCardModule } from "@angular/material/card";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { MatExpansionModule } from "@angular/material/expansion";
import { OrientacaoDecisorComponent } from "./avaliacoesDeQualidade/orientacao-decisor/orientacao-decisor.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { CreateChangeManagerModalComponent } from "./create-change-manager-modal/create-change-manager-modal.component";
import { RiskStatusModalComponent } from "./demandas/demandas/risk-status-modal/risk-status-modal.component";
import { CreateManualChangeModalComponent } from "./create-change-manager-modal/create-manual-change-modal/create-manual-change-modal.component";
import { CreateRequestModalComponent } from "./demandas/demandas/create-request-modal/create-request-modal.component";
import { BuildTrackingModalComponent } from "./demandas/demandas/build-tracking-modal/build-tracking-modal.component";
import { AddTenantBinding } from "./liberacoesUsuarios/add-tenant-biding-modal/add-tenant-biding-modal.component";
import { LoginService } from "@account/login/login.service";
import { OAuthModule } from "angular-oauth2-oidc";
import { DocumentacaoItemQualidadeModalComponent } from "./avaliacoesDeQualidade/documentacao-item-qualidade-modal/documentacao-item-qualidade-modal.component";
import {
    ReducaoCustosPerfilUsuarioServiceProxy,
    RetrabalhoPerfilUsuarioServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { GestaoMudancasComponent } from "./gestaoMudancas/gestaoMudancas.component";
import { AprovacoesComponent } from "./gestaoMudancas/aprovacoes/aprovacoes.component";
import { CreateChangeManagerNativeModalComponent } from "./gestaoMudancas/create-change-manager-native-modal/create-change-manager-native-modal.component";
import { ApproveReproveChangeModalComponent } from "./gestaoMudancas/aprovacoes/approve-reprove-change-modal/approve-reprove-change-modal.component";
import { JustifyModalComponent } from "./gestaoMudancas/aprovacoes/approve-reprove-change-modal/justificativas/justify-modal/justify-modal.component";
import { CreateOrEditApproverModalComponent } from "./gestaoMudancas/create-or-edit-approver-modal/create-or-edit-approver-modal.component";
import { AvaliacaoAmbienteAgendamentoComponent } from "./avaliacaoAmbiente/agendamento/agendamento.component";
import { AvaliacaoAmbienteInventarioObjetosComponent } from "./avaliacaoAmbiente/inventarioObjetos/inventarioObjetos.component";
import { AvaliacaoAmbienteInformacaoObjetoComponent } from "./avaliacaoAmbiente/inventarioObjetos/infomacaoObjeto/infomacaoObjeto.component";
import { AvaliacaoAmbienteDebitosTecnicosDoObjetoComponent } from "./avaliacaoAmbiente/inventarioObjetos/infomacaoObjeto/debitosTecnicosDoObjeto/debitosTecnicosDoObjeto.component";
import { TreeTableModule } from "primeng/treetable";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CodeReviewComponent } from "./codeReview/codeReview.component";
import { AvaliacoesCodeReviewComponent } from "./codeReview/avaliacoes/avaliacoes.component";
import { CodeReviewModalComponent } from "./codeReview/avaliacoes/code-review-modal/code-review-modal.component";
import { CodeReviewHistoryModalComponent } from "./codeReview/avaliacoes/code-review-history-modal/code-review-history-modal.component";
import { CodeReviewCommentsModalComponent } from "./demandas/demandas/create-or-edit-demanda-modal/code-reviewer-comments-modal/code-reviewer-comments-modal.component";
import { MyProfileComponent } from "./myProfile/myProfile.component";
import { BasicInformationComponent } from "./myProfile/basicInformation/basicInformation.component";
import { NewsFeedComponent } from "./myProfile/newsFeed/newsFeed.component";
import { LastAccessedClientsComponent } from "./myProfile/lastAccessedClients/lastAccessedClients.component";
import { RecentDemandsComponent } from "./myProfile/recentDemands/recentDemands.component";
import { TagModule } from "primeng/tag";
import { TimelineModule } from "primeng/timeline";
import { IndicatorsComponent } from "./myProfile/indicators/indicators.component";
import { NpsComponent } from "./myProfile/indicators/nps/nps.component";
import { NpsPerfilUsuarioServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppModule } from "..";
import { ReducaoCustoComponent } from "./myProfile/indicators/reducao-custo/reducaoCusto.component";
import { RetrablhoComponent } from "./myProfile/indicators/retrabalho/retrabalho.component";
import { ConfigurationDeployComponent } from "./deploy/configurationDeploy.component";
import { TabsConfigurationComponent } from "./deploy/tabsConfiguration/tabsConfiguration.component";
import { TabParametersComponent } from "./deploy/tabParameters/tabParameters.component";
import { TabSchedulingExceptionComponent } from "./deploy/tabsSchedulingException/tabSchedulingException.component";
import { ConfigurationTransportSchedulingComponent } from "./deploy/configurationTransportScheduling/configurationTransport.component";
import { ScheduleDeployModalComponent } from "./deploy/configurationTransportScheduling/scheduleDeploy/scheduleDeploy-modal.component";
import { CalendarModule } from "primeng/calendar";
import { RecurringPeriodsComponent } from "./deploy/createPeriods/recurringPeriods.component";
import { CreateOrEditPeriodModalComponent } from "./deploy/createOrEditPeriod/createOrEditPeriod-modal.component";
import { AppprovalUserDeployComponent } from "./deploy/ApprovalUsers/appprovalUserDeploy.component";
import { tabGerenciarProjetosComponent } from "./projeto/tabGerenciarProjetos/tabGerenciarProjetos.component";
import { ConfigurationProjetosComponent } from "./projeto/configurationProjeto.component";
import { tabsProjetoConfigurationComponent } from "./projeto/tabsProjetoConfiguration/tabsProjetoConfiguration.component";
import { CreateOrEditProjetoModalComponent } from "./projeto/create-or-edit-projeto-modal/create-or-edit-projeto-modal.component";
import { InformationDeployModalComponent } from "./gestaoMudancas/create-change-manager-native-modal/information-deploy/informationDeploy.component";
import { DropzoneMaterialModule } from "@ngx-dropzone/material";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { GraphicsHostComponent } from "./dashboard/graphics-host/graphics.component";
import { CostReductionComponent } from "./dashboard/graphics-tenant/cost-reduction/cost-reduction.component";
import { ReworkComponent } from "./dashboard/graphics-tenant/rework/rework.component";
import { NonComplianceComponent } from "./dashboard/graphics-tenant/non-compliance/non-compliance.component";
import { GmudComponent } from "./dashboard/graphics-tenant/gmud/gmud.component";
import { CheckboxModule } from "primeng/checkbox";
import { CardModule } from "primeng/card";
import { ReportsHostTenantComponent } from "./dashboard/reports-host-tenant/reports-host-tenant.component";
import { AccordionModule } from "primeng/accordion";
import { InputSwitchModule } from "primeng/inputswitch";
import { MenubarModule } from "primeng/menubar";
import { DialogModule } from "primeng/dialog";
import { MessageService } from "primeng/api";
import { ConfigurationQualityComponent } from "./avaliacoesDeQualidade/configuration/configuringQualityAssessment.component";
import { SavingGlobalComponent } from "./dashboard/saving-global/saving-global.component";
import { RequestComponent } from "./dashboard/graphics-tenant/request/request.component";
import { ConfigurationRiskManagementComponent } from "./riskManagement/configuration/configurationRiskManagement.component";
import { MessagesModule } from "primeng/messages";
import { EnvironmentAssessmentComponent } from "./dashboard/graphics-tenant/environment-assessment/environment-assessment.component";
import { TimelineDemandModule } from "@app/shared/layout/timeline/timeline.module";
import { ApprovalHistoryComponent } from "./gestaoMudancas/historicoAprovacoes/historicoAprovacoes.component";
import { TimelineDemandaComponent } from "./dashboard/graphics-tenant/timeline-demanda/timeline-demanda.component";
import { PromptSelectionComponent } from "./promptia/prompt-selection/prompt-selection.component";
import { PromptChatComponent } from "./promptia/prompt-chat/prompt-chat.component";
import { DividerModule } from "primeng/divider";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { DocumentationHistoryComponent } from "./promptia/documentation-history/documentation-history.component";
import { SpinnerService } from "@shared/services/spinner.service";
import { SpinnerComponent } from "./promptia/spinner/spinner.component";
import { ChangeRequestConfigComponent } from "./changeRequest/changeRequestConfig.component";
import { CreateOrEditDepartamentModalComponent } from "./changeRequest/create-or-edit-departament-modal/create-or-edit-departament-modal.component";
import { CreateOrEditChangeRequestComponent } from "./changeRequest/createChangeRequest/list-change-request.component";
import { CreateOrEditChangeRequestModalComponent } from "./changeRequest/createChangeRequest/create-or-edit-change-request-modal/create-or-edit-change-request-modal.component";
import { ApprovalChangeRequestComponent } from "./changeRequest/approvalChangeRequests/list-approval-change.component";
import { ApprovalOrDisapproveChangeRequestModalComponent } from "./changeRequest/approvalChangeRequests/approval-change-request-modal/approval-change-request-modal.component";
import { JustificationModalComponent } from "./changeRequest/approvalChangeRequests/approval-change-request-modal/justificationOfTheAction/justification-modal.component";
import { HistoryApprovalChangeRequestComponent } from "./changeRequest/historyChangeRequest/historyChangeRequestModal.component";
import { HistorysChangeRequestModalComponent } from "./changeRequest/historyChangeRequest/history-change-request-modal/history-change-request-modal.component";
import { RescheduleChangeRequestModalComponent } from "./changeRequest/createChangeRequest/rescheduleChangeRequest/reschedule-change-request-modal.component";
import { ResultsObtainedComponent } from "./dashboard/results-obtained/results-obtained.component";
import { InformationDemandModalComponent } from "./demandas/demandas/create-or-edit-demanda-modal/information-demand/informationDemand.component";
import { TypewriterComponent } from "./promptia/typewriter/typewriter.component";
import { ListboxModule } from 'primeng/listbox';
import { ArchiveListComponent } from "./promptia/archive-list/archive-list.component";

@NgModule({
    declarations: [
        AvaliacoesDeQualidadesComponent,
        LiberacoesUsuariosComponent,
        DemandaModuloLookupTableModalComponent,
        DemandasComponent,
        ReportsHostTenantComponent,
        CreateOrEditDemandaModalComponent,
        BoxKanbanComponent,
        DemandaUserLookupTableModalComponent,
        DashboardComponent,
        PromptSelectionComponent,
        PromptChatComponent,
        DocumentationHistoryComponent,
        KanbanComponent,
        SavingGlobalComponent,
        KanBanAmbienteModalComponent,
        CardLiberacaoUsuarioComponent,
        HistoricoAvaliacaoCodigoComponent,
        ItensAvaliadosComponent,
        CodigoSapComponent,
        JustificativaAvaliacaoComponent,
        JustificativaDecisorComponent,
        OrientacaoDecisorComponent,
        CreateChangeManagerModalComponent,
        RiskStatusModalComponent,
        CreateManualChangeModalComponent,
        CreateRequestModalComponent,
        BuildTrackingModalComponent,
        AddTenantBinding,
        DocumentacaoItemQualidadeModalComponent,
        GestaoMudancasComponent,
        GraphicsHostComponent,
        CreateChangeManagerNativeModalComponent,
        AprovacoesComponent,
        ApproveReproveChangeModalComponent,
        JustifyModalComponent,
        CreateOrEditApproverModalComponent,
        AvaliacaoAmbienteAgendamentoComponent,
        AvaliacaoAmbienteInventarioObjetosComponent,
        AvaliacaoAmbienteInformacaoObjetoComponent,
        AvaliacaoAmbienteDebitosTecnicosDoObjetoComponent,
        CodeReviewComponent,
        AvaliacoesCodeReviewComponent,
        CodeReviewModalComponent,
        CodeReviewHistoryModalComponent,
        CodeReviewCommentsModalComponent,
        BasicInformationComponent,
        MyProfileComponent,
        NewsFeedComponent,
        LastAccessedClientsComponent,
        RecentDemandsComponent,
        IndicatorsComponent,
        NpsComponent,
        ReducaoCustoComponent,
        RetrablhoComponent,
        ConfigurationDeployComponent,
        TabsConfigurationComponent,
        TabParametersComponent,
        TabSchedulingExceptionComponent,
        ConfigurationTransportSchedulingComponent,
        ScheduleDeployModalComponent,
        AppprovalUserDeployComponent,
        RecurringPeriodsComponent,
        CreateOrEditPeriodModalComponent,
        tabGerenciarProjetosComponent,
        tabsProjetoConfigurationComponent,
        ConfigurationProjetosComponent,
        CreateOrEditProjetoModalComponent,
        InformationDeployModalComponent,
        ReworkComponent,
        CostReductionComponent,
        ResultsObtainedComponent,
        NonComplianceComponent,
        RequestComponent,
        TimelineDemandaComponent,
        GmudComponent,
        SpinnerComponent,
        ConfigurationQualityComponent,
        ConfigurationRiskManagementComponent,
        EnvironmentAssessmentComponent,
        ApprovalHistoryComponent,
        ChangeRequestConfigComponent,
        CreateOrEditDepartamentModalComponent,
        CreateOrEditChangeRequestComponent,
        CreateOrEditChangeRequestModalComponent,
        ApprovalChangeRequestComponent,
        ApprovalOrDisapproveChangeRequestModalComponent,
        JustificationModalComponent,
        TypewriterComponent,
        ArchiveListComponent,
        HistoryApprovalChangeRequestComponent,
        HistorysChangeRequestModalComponent,
        RescheduleChangeRequestModalComponent,
        InformationDemandModalComponent,
    ],
    providers: [
        {
            provide: BsDatepickerConfig,
            useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig,
        },
        {
            provide: BsDaterangepickerConfig,
            useFactory:
                NgxBootstrapDatePickerConfigService.getDaterangepickerConfig,
        },
        {
            provide: BsLocaleService,
            useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale,
        },
        LoginService,
        MessageService,
        NpsPerfilUsuarioServiceProxy,
        MessageService,
        SpinnerService,
        ReducaoCustosPerfilUsuarioServiceProxy,
        RetrabalhoPerfilUsuarioServiceProxy,
        provideNgxMask(),
    ],
    imports: [
        AccordionModule,
        ProgressbarModule,
        FileUploadModule,
        MatCardModule,
        MenubarModule,
        DialogModule,
        DividerModule,
        CardModule,
        InputTextareaModule,
        InputSwitchModule,
        TimelineDemandModule,
        TimelineModule,
        CheckboxModule,
        TagModule,
        ToastModule,
        PanelModule,
        ButtonModule,
        MatExpansionModule,
        AutoCompleteModule,
        PaginatorModule,
        ListboxModule,
        ProgressSpinnerModule,
        MatSlideToggleModule,
        EditorModule,
        InputMaskModule,
        TableModule,
        TreeModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        TabsModule,
        InputTextModule,
        TooltipModule,
        AppCommonModule,
        UtilsModule,
        TabViewModule,
        MainRoutingModule,
        NgxChartsModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        NgSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        NgbAccordionModule,
        GridsterModule,
        AngularEditorModule,
        OAuthModule.forRoot(),
        TreeTableModule,
        BreadcrumbModule,
        AppModule,
        CalendarModule,
        NgxMaskDirective,
        NgxMaskPipe,
        DropzoneCdkModule,
        DropzoneMaterialModule,
        MatFormFieldModule,
        MessagesModule,
    ],
})
export class MainModule {}

<div class="card d-flex align-items-center justify-content-center p-4" *ngIf="loading && !isDemandHistory">
    <div class="col-12 d-flex justify-content-center mr-2">
        <h2>
            Tempo Médio de Transporte - {{demandHistory.averageTransportTotalTime == '0' ? 'Sem demandas concluídas' :
            demandHistory.averageTransportTotalTime}}
        </h2>
    </div>
    <div class="col-12 d-flex justify-content-center align-items-center flex-wrap">
        <p-tag icon="fa fa-solid fa-code" severity="dev" *ngIf="demandHistory.averageTransportDevelopment != '0'"
            [value]="'Desenvolvimento - ' + demandHistory.averageTransportDevelopment">
        </p-tag>
        <p-tag icon="fa fa-solid fa-cog" severity="homol" *ngIf="demandHistory.averageTransportHomologation != '0'"
            [value]="'Homologação - ' + demandHistory.averageTransportHomologation">
        </p-tag>
        <p-tag icon="fa fa-solid fa-truck" severity="prod" *ngIf="demandHistory.averageTransportProduction != '0'"
            [value]="'Produção - ' + demandHistory.averageTransportProduction">
        </p-tag>
        <p-tag icon="fa fa-solid fa-check" severity="finish" *ngIf="demandHistory.averageTransportFinish != '0'"
            [value]="'Concluída - ' + demandHistory.averageTransportFinish">
        </p-tag>
    </div>
</div>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion1">
</iframe>

<iframe class="col-12 col-md-6" frameborder="0" height="500" allowtransparency [src]="urlQuestion2">
</iframe>
